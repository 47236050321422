// form {
//   // width: 85%;
//   width: 90%;
//   position: absolute;
//   // top: 50%;
//   top: 48%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
form {
  min-width: 90%;
  max-width: 100%;
  @media screen and (min-width: 768px) {
    // min-width: 400px;
  }
}
@media (max-height: 600px) {
  .register-page {
    .register-form,
    .otp-form {
      text-align: center;
      padding: 1em 1.5em;
      padding-top: 0;
      padding-bottom: 0;
      .form-title {
        
        font-size: 1.4rem !important;
        
      }
  }
}
}
.register-page {
  .register-form,
  .otp-form {
    text-align: center;
    padding: 1em 1.5em;
    padding-top: 0;
    padding-bottom: 0;
    .form-title {
      margin: 0.3em 0;
      margin-top: 0;
      // color: #fff;
      color: #261B62;

      -webkit-text-stroke-width: 4;
      -webkit-text-stroke-color: #ef1c26;
      font-family: RobotoBold;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.12px;
      // text-transform: uppercase;
      padding-top: 0;
  margin-top: 0;
    }
    .otp-sub-title{
      color: #fff;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-group {
      .text {
        margin-right: 10px;
      }
      label:not(:first-child) {
        margin-left: 10px;
      }
    }
    .timer-wrapper {
      margin: 30px auto 20px;
      display: flex;
      justify-content: center;
      .timer {
        width: 25px;
        border: 1px solid;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
        margin-right: 6px;
        color: #fff;
        font-family: SpaceGrotesk;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      span {
        color: #fff;
        font-family: SpaceGrotesk;
        font-size: 12px;
        // font-style: italic;
        font-weight: 600;
        line-height: normal;
        &.link {
          color: #261B62;
          margin-left: 6px;
          cursor: pointer;
          text-decoration-line: underline;
        }
      }
    }
    .reg-lang {
      .reg-lang-title {
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: Myriad Pro;
      }
    }
    .link {
      color: #f4b747;
      font-family: SpaceGrotesk;
      text-decoration: underline;
    }
  }
  // .register-title {
  //   position: relative;
  //   bottom: 2em;
  // }
  .smoker-text {
    color: #fff;
    font-family: SpaceGrotesk;
    font-size: 14px;
    font-style: italic;
    // font-style: italic;
    // font-weight: 600;
    // line-height: normal;
  }
}

@media (max-height: 600px) {

}
.terms-link {
  color: #261B62
}
#captcha-container{
  transform: scale(.7);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
