@import "../Register/Register";

.participated-form {
  // box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  background-color: #261B62;
  border-radius: 16px;
  padding: 1em;
  width: 100%;
  max-width: 350px;
  color: #fff;
  // height: 100px;
}
.Participated-container {
  // padding: 1em;
  color: #fff;
}
.scan-text{
  font-size: 1.5rem;
}



